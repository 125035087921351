class MessageBarTemplateGloIT extends BATComponent {
	/* Lifecycle event callbacks */
	beforeLoad() {
		// console.log('MessageBarTemplateDefault before load');
	}

	afterLoad() {
		this.$el = $(this);

		const turnOnMessage = (alert) => {
			const cart = $.parseJSON(
				Utils.getLocalStorage('commerce-cart-storage')
			);

			const accountModified = $.parseJSON(Utils.getLocalStorage('account-modified'));

			if (cart) {
				const $messageBar = this.$el.find('.bat-messagebar--default');
				const $messageEle = this.$el.find(
					'.bat-messagebar--default-message'
				);
				const $errorMessageEle = this.$el.find(
					'.bat-messagebar--default-message-error'
				);

				$messageEle.removeClass('active');
				$errorMessageEle.removeClass('active');

				const cartLastItem = cart.lastItemAdded;
				const cartLastActionTaken = cart.lastActionTaken;
				let { cartMessage } = cart;
				const $firstParagraph = $messageEle.find('p').eq(0);
				const $itemNameEle = $messageEle.find('.item-name');

				if (!alert && !accountModified) {
					if (cartMessage) {
						if(cartMessage.indexOf(':') > -1) {
							cartMessage = 'La quantità richiesta non è al momento disponibile, ti invitiamo a riprovare più tardi';
						} else if (cartMessage.indexOf('limit exceed') > -1) {
							cartMessage = 'Hai raggiunto il limite mensile di acquisto per questo voucher. Aspetta fino al prossimo mese o acquistane altre tipologie';
						}
						$errorMessageEle.empty();
						$errorMessageEle.html(`<p>${cartMessage}</p>`);
						$errorMessageEle.addClass('active');
						$messageBar.addClass('active');
						if (window.location.href.indexOf('/app/') > -1) {
							setTimeout(() => {
								$messageBar.removeClass('active');
								$errorMessageEle.removeClass('active');
							}, 5000);
						}
						return;
					}

					if (cartLastItem && cartLastActionTaken === 'add') {
						$itemNameEle.remove();
						$(`<p class="item-name"> ${cartLastItem} </p>`).insertAfter(
							$firstParagraph
						);

						$messageBar.addClass('active');
						$messageEle.addClass('active');
					}
				} else if(accountModified) {
					Utils.setLocalStorage('account-modified', false);
					$messageEle.html('<p>Hai salvato le informazioni sull\'account.</p>');
					$messageBar.addClass('active');
					$messageEle.addClass('active');
				} else if(alert === 'device') {
					$messageEle.html('<p>Grazie! Il tuo dispositivo è stato registrato correttamente.</p>');
					$messageBar.addClass('active');
					$messageEle.addClass('active');
				} else {
					$messageEle.html('<p>La sottoscrizione è stata attivata</p>');
					$messageBar.addClass('active');
					$messageEle.addClass('active');
				}
			}
		};

		$(window).on('cart-update', () => {
			turnOnMessage();
		});

		$(window).on('alert-update', () => {
			turnOnMessage(true);
		});

		$(window).on('device-registered', () => {
			turnOnMessage('device')
		});
	}

	beforeUpdate() {
		// console.log('HeadlineTemplate before update');
	}

	afterUpdate() {
		// console.log('HeadlineTemplate after update');
	}

	unload() {
		// console.log('HeadlineTemplate after unload');
	}
}

!customElements.get('bat-messagebar-gloit') &&
	customElements.define('bat-messagebar-gloit', MessageBarTemplateGloIT);
